<template>
  <div class="yinpin container" v-if="canShow">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <Article :article="article" :audioSrc="audioSrc" />
    <OpenApp :path="path" :id="id" />
    <Advertisement :pos="'efhoNR'" :path="path" :id="id" />
    <Comment :list="commentList" v-show="commentList.can_comment == 2 && commentList.length > 0" :id="id" :path="path" />
    <OpenApp2 :path="path" :id="id" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Kcz from "@/components/kcz";
  import Tuijian from "@/components/tuijian";
  import OpenApp2 from "@/components/openApp2";
  import OpenApp from "@/components/openApp";
  import Article from "@/components/article";
  import linkApp from "@/utils/linkApp";
  import Comment from "@/components/comment";
  import Advertisement from "@/components/advertisement";
  import { getArticleDetail, getCommit } from "@/utils/api";
  import { wxShare } from "@/utils/wxShare";
  export default {
    name: "yinpin",
    components: {
      Tanchuang,
      Kcz,
      Tuijian,
      OpenApp2,
      OpenApp,
      Article,
      Advertisement,
      Comment,
    },
    data() {
      return {
        canShow: false,
        path: "kczAudioDetail",
        id: "",
        commentList: [],
        article: {
          title: "",
          source: "",
          time: "",
          readNum: "",
          content: "",
        },
        adver: {
          title: "",
          src: "",
        },
        audioSrc: "",
        share_config: {},
      };
    },
    methods: {
      linkApp() {
        const data = {};
        linkApp(data);
      },
      async getDetail(id) {
        const res = await getArticleDetail(id);
        const data = res.data.data;
        this.article.title = data.title;
        this.article.source = data.source_from;
        this.article.time = data.published_at;
        this.article.readNum = data.views;
        this.article.content = data.content;
        this.audioSrc = data.materials.audio.src;
        this.article.can_comment = data.can_comment;
        this.share_config = data.share_config;
        document.title = data.title;
        this.canShow = true;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
      },
      async getCommit(id) {
        const res = await getCommit(id);
        const data = res.data.data;
        this.commentList = data.slice(0, 3).map(val => {
          return {
            avatar: val.avatar,
            name: val.nickname,
            time: val.created_at_human,
            like: val.likes_count,
            content: val.content,
          };
        });
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getDetail(this.id);
      this.getCommit(this.id);
    },
  };
</script>

<style lang="scss">
  .audio-wrapper {
    background-color: #fff;
    margin: 0.4rem auto;
    width: 9.2rem;
    border: 1px solid #ccc;
    border-radius: 0.16rem;
    height: 1rem;
    display: flex;
    align-items: center;
  }
  .audio-left {
    text-align: center;
    width: 18%;
  }
  .audio-left img {
    width: 0.72rem;
    height: 0.72rem;
    position: relative;
    display: initial; /* 解除与app的样式冲突 */
    cursor: pointer;
  }
  .audio-right {
    margin-right: 2%;
    width: 80%;
  }
  .progress-bar-bg {
    background-color: #d9d9d9;
    position: relative;
    height: 0.1rem;
    cursor: pointer;
    border-radius: 0.1rem;
  }

  .progress-bar {
    background-color: #f04040;
    width: 0;
    height: 0.1rem;
    border-radius: 0.1rem;
  }

  .progress-bar-bg span {
    content: " ";
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #404040;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.1rem;
    margin-left: -0.1rem;
    cursor: pointer;
  }

  .audio-time {
    overflow: hidden;
    margin-top: 0.1rem;
  }

  .audio-length-total {
    float: right;
    font-size: 12px;
    color: #494949;
  }

  .audio-length-current {
    float: left;
    font-size: 12px;
    color: #494949;
  }
</style>
